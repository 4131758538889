<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Web Bot Logs</div>
        <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="input-group input-group-sm custom-form-group mb-0 me-2"
                v-bind:style="[searchnamebtnactive ? { width: '328px' } : { width: '275px' }]">
                <input type="text" v-model="searchfullname" class="form-control text-capitalize"
                    placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
                    autocomplete="off" maxlength="30" v-on:keyup.enter="searchFnameVoter(searchfullname)" @keypress="isLetterWithSpace($event)" :disabled="hidesearchname"
                    style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
                    @click="searchFnameVoter(searchfullname)" :style="{ width: '50px' }">
                    <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                        v-if="searchnameloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameVoterStop()"
                    :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times"
                        aria-hidden="true"></i>
                </button>
            </div>
            <div class="input-group input-group-sm custom-form-group mb-0"
                v-bind:style="[searchmobbtnactive ? { width: '275px' } : { width: '220px' }]">
                <input type="text" v-model="searchmobile" class="form-control" id="searchmobInput"
                    placeholder="Search By Mobile No." :disabled="hidesearchmob" aria-label="Search"
                    aria-describedby="basic-addon1" autocomplete="off" maxlength="10" @paste="onPasteMobile"
                    @keypress="mobileSearchFormat" v-on:keyup.enter="searchMobVoter(searchmobile)" style="padding: 6px 15px;" />
                <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchmobile.length <= 3"
                    @click="searchMobVoter(searchmobile)" :style="{ width: '50px' }">
                    <span v-if="!searchmobloader"><i class="pi pi-search" aria-hidden="true"></i></span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                        v-if="searchmobloader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchMobVoterStop()"
                    :style="{ width: '50px' }" v-show="searchmobbtnactive"><i class="pi pi-times"
                        aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="chatbotLogList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit"
            :paginator="true" :lazy="true" :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="lo1"
            @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="Name" header="Name" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        <div class="fw-bold">{{ data.lo12 ? data.lo12 : "N/A" }}</div>
                        <div>{{ data.lo13 ? data.lo13 : "N/A" }}</div>
                    </div>
                </template>
            </Column>
            <Column field="datetime" header="Date Time" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div>{{ format_timestamp(data.lo2) }}</div>
                </template>
            </Column>
            <Column field="message" header="Webbot Message" class="justify-content-between" headerStyle="width: 50%"
                bodyStyle="width: 50%">
                <template #body="{ data }">
                    <div>
                        <div style="max-width: 495px; word-wrap: break-word;"><span v-html="replacebrake(data.lo4)"></span></div>
                        <div v-if="data.lo16 != null && data.lo16 != ''">
                            <img @click="whatsappimagepreview(data)" :src="data.lo16"
                                style="vertical-align: middle; height:50px"
                                class="whatsapp-Sent-image cursor-pointer whatsapp-imger-overlay" />
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 13%"
                bodyStyle="width: 13%">
                <template #body="{ data }">
                    <button type="button" class="btn custom-view-detail-btn" @click="whatsappHistoryModalOpen(data)">
                        History
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- whatsApp History modal start here -->
    <div class="modal-mask" v-if="whatsappmodalsflag">
        <div
            class="modal-dialog modal-md modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100 webchat-history-bg-color">
            <div class="modal-content vh-100 conversation">
                <div class="chatbot-header-outer-padding">
                    <div class="chatbot-header-inner-bg">
                        <div class="whatsapp-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img v-if="this.whatsappprofile != null && this.whatsappprofile != ''"
                                                :src="this.whatsappprofile" class="profile-header-avatar rounded-circle"
                                                alt="" width="46" height="46" />
                                            <img v-else src="/assets/images/profile.svg"
                                                class="profile-header-avatar rounded-circle" alt="" width="46"
                                                height="46" />
                                        </div>
                                        <div class="flex-grow-1" style="margin-left: 15px;">
                                            <div class="whatsapp-header-name text-capitalize">{{ this.whatsappname ?
                                                this.whatsappname :
                                                "N/A" }}</div>
                                            <div class="whatsapp-header-number">{{this.whatsappnumber}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <InputSwitch v-model="ApiLoadingSwitch" class="me-3" @change="switchLoadingStatus()" />
                                        <button type="button" title="Refresh" class="btn custom-outline-refresh-btn me-3"
                                            @click="getWebBotchatlogs()">
                                            <i class="pi pi-refresh refresh-btn-color"></i>
                                        </button>
                                        <button type="button" class="btn-close custom-chat-btn-close" @click="whatsappHistoryModalClose()"></button>
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="chatbox-right-outer">
                        <div class="chatbox-inner-content">
                            <div class="widget-chat p-dialog-content">
                                <div id="chatcontainer" class="conversation-container" v-if="!showloadermodal">
                                    <div v-for="(value, key ) in whatsAppChatHistory" :key="key" class="message-outer-body">
                                        <div class="text-center chatbot-date-header">
                                            <div class="date-outer-header">
                                                <span class="date-inner-header"><span class="date-label">{{ format_date(key) }}</span></span>
                                            </div>
                                        </div>
                                        <div v-for="msg in value" :key="msg">
                                            <div v-if="msg.lo3 == 1">
                                                <div class="message sent "  >
                                                    <div class="message-send-padding">
                                                        <div v-if="msg.lo16" class="mb-1">
                                                            <img @click="whatsappimagepreview(msg)" :src="msg.lo16"
                                                                style="vertical-align: middle;"
                                                                class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                        </div>
                                                        <div v-if="msg.lo14 != 4">
                                                            <span v-html="replacebrake(msg.lo4)"></span>
                                                        </div>
                                                        <div v-if="msg.lo14 == 4">{{ msg.lo4 }}</div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img :src="msg.lo6"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                    </div>
                                                </div>
                                                <div class="metadata-send" style="min-width: 100%;"><span class="time">{{
                                                format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                            <div v-if="msg.lo3 == 2">
                                                <div class="message received">
                                                    <div class="">
                                                        <div v-if="msg.lo16" class="mb-1">
                                                            <img @click="whatsappimagepreview(msg)" :src="msg.lo16"
                                                            style="vertical-align: middle; width: 100%;border-radius: 9.41px 0px 0px 0px;"
                                                                class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                        </div>
                                                        <div v-if="msg.lo14 != 4" class="message-text-received message-received-padding">
                                                            <span v-html="replacebrake(msg.lo4)"></span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div v-if="msg.lo14 == 4">
                                                            <button type="button" class="whatsappbtnreply">{{ msg.lo4 }}</button>
                                                        </div>
                                                        <div class="mt-2" v-if="msg.lo17 || msg.lo18 || msg.lo19 || msg.lo20 || msg.lo21">
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo17">{{ msg.lo17 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo18">{{ msg.lo18 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo19">{{ msg.lo19 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo20">{{ msg.lo20 }}</button>
                                                            <button type="button" class="whatsappbtnreply" v-if="msg.lo21">{{ msg.lo21 }}</button>
                                                        </div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img :src="msg.lo6"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-Sent-image p-mt-1" /></a>
                                                    </div>
                                                </div>
                                                <div class="metadata-received" style="min-width: 100%;"><span class="time">{{
                                                format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloadermodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- whatsApp History modal end here -->
    <div v-if="ImagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Image Preview</h4>
                    <button class="btn-close" @click="whatsappimagepreviewClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../../service/ApiService";
import moment from "moment";

export default {
    data() {
        return {
            chatbotLogList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            whatsappmodalsflag: false,
            showloadermodal: false,
            rowId: "",
            whatsAppChatHistory: [],
            whatsappname: "",
            whatsappnumber: "",
            ApiLoadingSwitch: false,
            showloaderbtn: false,
            file1: null,
            uploadfilename: null,
            ImagePreviewDialog: false,
            disabledWhatsAppInputPermission: '',
            whatsappprofile: '',
            searchmobile: '',
            searchmobloader: false,
            searchfullname: '',
            searchnameloader: false,
            searchmobbtnactive: false,
            searchnamebtnactive: false,
            hidesearchname: false,
            hidesearchmob: false,
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        this.getWebBotLogs();
    },
    beforeUnmount() {
        clearInterval(this.timer3);
    },
    methods: {
        getWebBotLogs(item) {
            this.loading = true;
            this.ApiService.getWebBotLogs(item).then((data) => {
               
                if (data.success === true) {
                    this.chatbotLogList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                    this.searchnameloader = false;
                    this.searchmobloader = false;
                    this.hidesearchname = false;
                    this.hidesearchmob = false;
                } else {
                    this.loading = false;
                    this.chatbotLogList = null;
                    this.totalRecords = 0;
                    this.searchnameloader = false;
                    this.searchmobloader = false;
                    this.hidesearchname = false;
                    this.hidesearchmob = false;
                }

            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getWebBotLogs({ page_no: this.page_no });
        },
        whatsappHistoryModalOpen(e) {
            this.rowId = e.lo11;
            this.whatsappprofile = e.lo10;
            this.whatsappname = e.lo12;
            this.whatsappnumber = e.lo13;
            this.disabledWhatsAppInputPermission = e.whatsapp;
            this.whatsappmodalsflag = true;
            this.getWebBotchatlogs();
        },
        getWebBotchatlogs() {
            this.showloadermodal = true;
            this.ApiService.getWebBotchatlogs({ lo11: this.rowId }).then((data) => {
                if (data.success == true) {
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = data.records;
                    this.scrollmessage();
                }
            });
        },
        whatsappHistoryModalClose() {
            this.whatsappmodalsflag = false;
            clearInterval(this.timer3);
            this.ApiLoadingSwitch = false;
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        format_timestamptime(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("hh:mm a");
            } else {
                return 'N/A';
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD MMMM YYYY");
            }
        },
        scrollmessage() {
            console.log("scroll success");
            setTimeout(() => {
                var messageBody = document.querySelector('#chatcontainer');
                // console.log(messageBody);
                if (messageBody != null) {
                    messageBody.scrollTop = messageBody.scrollHeight;
                }
            }, 1000);
        },
        whatsappimagepreview(data) {
            this.previewimg = data.lo16;
            this.ImagePreviewDialog = true;
        },
        whatsappimagepreviewClose() {
            this.ImagePreviewDialog = false;
        },
        // Search Voter Header Input function start here
        searchMobVoter(
            searchmobile
        ) {
            this.hidesearchmob = true;
            this.searchmobloader = true;
            this.lo13 = searchmobile;
            this.getWebBotLogs({
                page_no: this.page_no,
                lo13: this.lo13,
            });
            this.searchmobbtnactive = true;
        },
        searchMobVoterStop() {
            this.searchmobbtnactive = false;
            this.searchmobile = "";
            this.getWebBotLogs({
                page_no: this.page_no,
            });
            this.searchmobloader = false;
        },
        searchFnameVoter(
            searchfullname
        ) {
            this.hidesearchname = true;
            this.searchnameloader = true;
            this.lo12 = searchfullname;
            this.getWebBotLogs({
                page_no: this.page_no,
                lo12: this.lo12,
            });
            this.searchnamebtnactive = true;
        },
        searchNameVoterStop() {
            this.searchnamebtnactive = false;
            this.searchfullname = "";
            this.getWebBotLogs({
                page_no: this.page_no,
            });
            this.searchnameloader = false;
        },
        // Search Voter Header Input function end here
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9]+$/.test(mobvalue)) return true;
            else event.preventDefault();
        },
        mobileSearchFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("searchmobInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        replacebrake(html) {
            const doc = html;
            return doc.replace(/(\r)*\n/g, '<br>')
        },
        switchLoadingStatus() {
            if (this.ApiLoadingSwitch == true) {
                this.timer3 = window.setInterval(() => {
                    this.getwebchatlogstimer();
                }, 2000);
            } else {
                clearInterval(this.timer3);
            }
        },
        getwebchatlogstimer() {
            this.ApiService.getWebBotchatlogs({ lo11: this.rowId }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.records;
                }
            });
        },
    },
};
</script>
<style scoped>
/* new chatbot css start here */
.chatbot-header-outer-padding {
    font-family: 'Inter-Regular';
    background-color: #ffffff;
    width: 100%;
    padding: 0;
}

.whatsapp-header {
    padding: 13px 20.5px;
    gap: 70px;
    border-radius: 12px;
    box-shadow: 0px 1px 5.6px 0px #00000026;
    background: #FFFFFF;
    height: 72px;
}

.whatsapp-header-name {
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #000000;
    margin-bottom: 6px;
    height: 21px;
    padding-top: 4px;
}

.whatsapp-header-number {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #C4C4D5;
    height: 15px;
}

.chatbot-header-inner-bg {
    padding: 21px 15px 19px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}

.whatsapp-header-content {
    background: #F1F2F5;
    padding: 6.02px 8.69px 6.02px 8.69px;
    gap: 4.68px;
    border-radius: 7.35px;
    opacity: 0px;
}

.whatsapp-header-content .whatsapp-header-content-label {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    /* text-align: center; */
    color: #8D93A5;
}
/* .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    position: relative;
    margin: 8px 0;
    max-width: 20%;
    word-wrap: break-word;
} */
.message.received {
    background: #4849A1;
    border-radius: 9.32px 9.32px 0px 9.32px;
    float: right;
    width: auto;
}


.message .message-received-padding {
    padding: 13.14px 17.29px 27.17px 17.77px;
}
.message .message-send-padding {
    padding: 17.19px 16px 16.81px 12px;
}

.message .message-text-received {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;

}

.message .message-text-send {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}



.whatsappbtnreply {
    width: 100%;
    border: 0;
    /* margin-bottom: 6px; */
    background-color: transparent;
    font-family: 'Inter-Regular';
    border-radius: 0;
    padding: 14.2px 16.65px;
    border-top: 0.88px solid rgba(214, 216, 216, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: #FFFFFF;
}

.whatsappbtnreply:disabled {
    background: #ced4da;
}
.orange-btn-disabled-color {
    background-color: #F95959 !important;
}

.metadata-received .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: left;
    color: #667781;

}
.metadata-received {
    margin-top: 9.64px;
    line-height: 12.73px;
    float: right;
    width: 100%;
    text-align: right;
}
.metadata-send {
    margin-top: 8.54px;
    line-height: 12.73px;
    float: left;
}

.metadata-send .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: right;
    color: #667781;
}

.message-outer-body {
    display: inline-block;
    width: 100%;
    clear: both;
    position: relative;
    margin-bottom: 22px;
}

.alrt-msg-icon {
    width: 20.3px;
    height: 20.3px;
    margin: 4px;
}

.conversation {
    height: calc(100% - 12px);
    position: relative;
    background: url("/assets/images/whatsapp-icon/bg-chatbot.png") no-repeat;
    z-index: 0;
    background-size: contain;
}
.whatsapp-textbtn-outer {
    padding: 20.5px 19.5px;
    width: 100%;
    margin: 0;
}
.conversation-compose .send {
    background: #F95959;
    border-radius: 20px;
    position: relative;
    width: 66px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 8px 21px;
}
.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 40px;
    width: 100%;
    z-index: 2;
}
.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    color: #3D3D3D;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    padding: 12.5px 13px;
    resize: none;
    border-radius: 21px;
    font-weight: 500;
    line-height: 14.52px;
    border: 1px solid #DBDEE7;
    height: 40px;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    text-align: start;
    text-transform: capitalize;
    line-height: 14.52px;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    box-shadow: none;
}
.chatbot-date-header {
    margin-bottom: 35px;
}
.chatbot-date-header .date-outer-header {
    text-align: center;
    position: relative;
    border-bottom: 1px solid rgb(240, 240, 246);
}
.chatbot-date-header .date-inner-header {
    padding: 0px 15px;
    position: relative;
    top: 11px;
    background: #fff;
}
.chatbot-date-header .date-label {
    color: #8D93A5;
    font-family: 'Inter-Regular';
    font-size: 12px;
    line-height: 14.52px;
    padding: 6.79px 18.1px;
    border-radius: 12.06px;
    background: #F1F2F5;
    font-weight: 400;
    position: relative;
}
.conversation-container {
    height: calc(100% - 5px);
    padding: 0 25px;
    overflow: auto;
}
.message.sent {
    background: #F0F0F6;
    border-radius: 0px 10.52px 10.52px 10.52px;
    float: left;
}
.form-control.input-msg.custom-chat-group-textarea:disabled {
    background: #f7f7f7;
    border: 0;
}
.form-control.input-msg.custom-chat-group-textarea:disabled::placeholder {
    color: #fff;
}

.conversation-compose .send:disabled {
    background: #f7f7f7;
}
.messagereplyouter {
    background: #e1f1d2;
    padding: 8px;
    margin-bottom: 5px;
    box-shadow: -3px 0px 0px 0px rgb(72 73 161 / 75%);
    border-radius: 3px;
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}
.chat-alert-message-header-fixed {
    padding: 21px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}
/* #old css start here */

.chatbox-right-outer {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    padding: 0;
    height: 100%;
    overflow: auto;
}
.chatbox-right-outer .chatbox-inner-content {
    height: calc(100% - 0px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    padding: 2px 0;
    /* overflow: auto; */
    position: relative;
}
.p-dialog-content {
    padding: 0;
    height: 100%;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}
.conversation-container::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    padding: 0;
    position: relative;
    outline: none;
}


/* .conversation-compose .send:focus {
    opacity: 0.8;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}
.btn-border-radius-custom {
    border-radius: 0px 0px 10.52px 10.52px;
}
    .message.received, .message.sent, .metadata-received,
    .metadata-send {
        max-width: 60%;
    }
.custom-outline-refresh-btn {
    padding: 10px;
    line-height: 10px;
    height: 37px;
    width: 37px;
    background: #F4F4FA;
    border-radius: 50%;
}

.custom-outline-refresh-btn:hover {
    opacity: .75;
}

.custom-outline-refresh-btn .refresh-btn-color {
    color: #C4C4D5;
}
.custom-chat-btn-close {
    color: #C4C4D5;
    padding: 10px;
    line-height: 10px;
    height: 37px;
    width: 37px;
    background-color: #F4F4FA;
    border-radius: 50%;
    opacity: 1;
    box-sizing: border-box;
    font-size: small;
}
.webchat-history-bg-color {
    background-color: #FFFFFF;
}

.whatsapp-received-image{
    width: 100%;
}
</style>